export const handleLogin = () => {
    window.location.href =
        process.env.loginUrl! +
        "?fromPath=" +
        window.location.href;
};

export const handleToPortal = () => {
    window.location.href = process.env.portalUrl! + "/home";
};
export const handleToLogin = () => {
    window.location.href = process.env.portalUrl! + "/login";
};
export const handleToPotential = () => {
    window.location.href =
        process.env.portalUrl! + "/influencer/potential";
};
export const handleToVideo = () => {
    window.location.href =
        process.env.portalUrl! + "/videos/list?name=soaring";
};

export const handleToMusic = () => {
    window.location.href =
        process.env.portalUrl! + "/content/songs?name=popular";
};

export const handleToTag = () => {
    window.location.href =
        process.env.portalUrl! + "/content/hashtags?name=popular";
};

export const handleToCollection = () => {
    window.location.href =
        process.env.portalUrl! + "/tool/favorites";
};

export const handleToMonitor = () => {
    window.location.href =
        process.env.portalUrl! + "/toolkit/monitor";
};

export const handleToGlobalGoodsRank = () => {
    window.location.href =
        process.env.portalUrl! + "/products/sales";
};

export const handleToGlobalGoodsVideo = () => {
    window.location.href =
        process.env.portalUrl! + "/video/products";
};

export const handleToGlobalProductVideo = () => {
    window.location.href =
        process.env.portalUrl! + "/video/promotion?name=breakout";
};

export const handleToMoblieLogin = () => {
    window.location.href = process.env.publicUrl + "/mobile/login";
};
export const handleToMoblieList = (url: string, suburl: string) => {
    window.location.href =
        process.env.publicUrl +
        "/mobile/list" +
        `?name=${url}&subname=${suburl}`;
};

export const handleToMobileCollection = () => {
    window.location.href =
        process.env.publicUrl! + "/mobile/Collection?name=Goods";
};

export const handleToMobileUser = () => {
    window.location.href = process.env.publicUrl! + "/mobile/user";
};

export const handleToUrl = (url: string) => {
    window.location.href = process.env.publicUrl + url;
};
