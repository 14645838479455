import Fetch from '../utils/request'
import { getDayDate } from '@/utils/format'
// import database from "../global";
import { GetServerSidePropsContext } from 'next'
import InviteCode from '@/utils/inviteCode'
import { ParsedUrlQuery } from 'querystring'
import { getCookie } from 'cookies-next'
import { algorithm } from '@/utils/headers'
import { camel2Under, under2Camal } from '@/utils/lineToHump'

const getUrlParams = (url, params: any) => {
  if (params) {
    let paramsArray: any[] = []
    //拼接参数
    Object.keys(params).forEach((key) => paramsArray.push(camel2Under(key) + '=' + params[key]))
    if (url.search(/\?/) === -1) {
      url += '?' + paramsArray.join('&')
    } else {
      url += '&' + paramsArray.join('&')
    }
  }
  console.log('url~~ ', url)

  return url
}

function getToken(): any {
  const access: any = getCookie('authorized-token')
  if (!access) return undefined
  return JSON.parse(access).accessToken
}

const fetch = new Fetch()
const BaseUrl = process.env.portalApiUrl
const BaseUrl1 = process.env.publicApiDomain

type ContextType = GetServerSidePropsContext<ParsedUrlQuery> | {}
export async function subscribe(options: any, context: ContextType) {
  // console.log("----", document.referrer);
  return await fetch.put(`${BaseUrl}/api/v1/website/subscribe`, context, options)
}

// export async function authorList(options: any) {
//     return await fetch.get(`${BaseUrl}/api/v1/seo/author/list`, options);
// }

export async function googleOneLogin(data: any, context: ContextType) {
  // @ts-ignore
  return await fetch.post(`${BaseUrl1}/api/v1/user/third-login/id-token/google`, context, data)
}
export async function googleClientId(context: ContextType) {
  // @ts-ignore
  // console.log("ppppp111", database.value);
  return await fetch.get(
    `${BaseUrl1}/api/v1/user/third-login/client-id/google?system_id=8`,
    context
  )
}
// 获取文件内容
export async function getFileContent(url: string) {
  return await fetch.get(url, {
    'Content-Type': 'application/pdf'
  })
}
/**
 * 新增
 */

export async function getBlogDetail({
  seoUrl,
  language,
  context
}: {
  seoUrl: any
  language: any
  context: ContextType
}) {
  // console.log(`${BaseUrl}/api/v1/blogs/${seoUrl}?language=${language}`, '`${BaseUrl}/api/v1/blogs/${seoUrl}?language=${language}` ==>')
  return await fetch.get(`${BaseUrl}/api/v1/blogs/${seoUrl}?language=${language}`, context)
}

export async function getCateList({
  language,
  context,
}: {
  language: any
  context: ContextType
}) {
  return await fetch.get(
    `${BaseUrl}/api/v1/posts/category/list?language_code=${language}`,
    context
  )
}
export async function getPostList({
  language,
  categoryId,
  pageSize,
  pageNum,
  context,
}: {
  pageNum: any
  pageSize: any
  categoryId: any
  language: any
  context: ContextType
}) {
  return await fetch.get(
    `${BaseUrl}/api/v1/posts?language_code=${language}&category_id=${categoryId}&page_num=${pageNum}&page_size=${pageSize}`,
    context
  )
}

export async function getArticleDetail({
  language,
  url,
  context,
}: {
  url: any
  language: any
  context: ContextType
}) {
  return await fetch.get(
    `${BaseUrl}/api/v1/posts/${url}?language_code=${language}`,
    context
  )
}
export async function getCateDetail({
  language,
  url,
  context,
}: {
  url: any
  language: any
  context: ContextType
}) {
  return await fetch.get(
    `${BaseUrl}/api/v1/posts/${url}?language_code=${language}`,
    context
  )
}
export async function getBlogs({
  language,
  pageSize,
  pageNum,
  context,
  type
}: {
  language: any
  pageSize: any
  pageNum: any
  context: ContextType
  type: number
}) {
  return await fetch.get(
    `${BaseUrl}/api/v1/blogs?language=${language}&page_size=${pageSize}&page_num=${pageNum}&type=${type}`,
    context
  )
}
export async function getCountt(headers: any, id: any) {
  return await fetch.get2(`https://tiktok.livecounts.io/user/${id}`, headers)
}
export async function getTopFive(context: ContextType) {
  return await fetch.get(`${BaseUrl}/api/v1/homepage/hot-influencers`, context)
}
export async function getTopVideo(context: ContextType) {
  // console.log(";;;;;;", this);
  return await fetch.get(`${BaseUrl}/api/v1/homepage/hot-videos`, context)
}
export async function getTopContent(context: ContextType) {
  // console.log(";;;;;", this);
  return await fetch.get(`${BaseUrl}/api/v1/homepage/hot-content`, context)
}

export async function getUserCount(context: ContextType) {
  return await fetch.get(`${BaseUrl}/api/v1/website/author`, context)
}

/**
 *
 * @returns 获取seo 音乐目录
 */
export async function getSeoMusics(
  // cursor: number,
  // country_code: any,
  // title_pre: any
  paramStr: any,
  context: ContextType
) {
  let x = `${BaseUrl}/api/v1/seo/music/catalogue${paramStr}`
  // console.log(x);

  return await fetch.get(x, context)
}

/**
 *
 * @returns 获取seo 音乐详情
 */
export async function getSeoMusicDetails(id: string, context: ContextType) {
  return await fetch.get(`${BaseUrl}/api/v1/seo/tag/otherSimilar/${id}`, context)
}
//手机端
export async function getVersion(context: ContextType) {
  return await fetch.get(
    `${BaseUrl}/api/v1/rank/versions?rank_type=AUTHOR_FOLLOWER_RISING&rank_cycle=H24`,
    context
  )
}
export async function getUsers(version: any, context: ContextType) {
  return await fetch.post(`${BaseUrl}/api/v1/rank/author_follower_rising_rank`, context, {
    cycle: 'H24',
    version: version,
    cursor: 0,
    //按达人增长数排序
    sort: 68
  })
}
export async function getUsers2(version: any, context: ContextType) {
  return await fetch.post(`${BaseUrl}/api/v1/rank/author_potential_rank`, context, {
    cycle: 'H24',
    version: version,
    cursor: 0
  })
}
export async function getUserTopSale(version: any, context: ContextType) {
  return await fetch.get(
    `${BaseUrl}/api/v1/rank/sales_author_rank?cycle=H24&is_commerce=false&version=${version}&cursor=0&sort=19&country_code=GB&size=3`,
    context
  )
}
export async function getVideo(version: any, context: ContextType) {
  return await fetch.get(
    `${BaseUrl}/api/v1/rank/video_like_rising_rank?cycle=H24&version=${version}&cursor=0`,
    context
  )
}
export async function getVideo2(version: any, context: ContextType) {
  return await fetch.get(
    `${BaseUrl}/api/v1/rank/video_play_total_rank?cycle=H24&version=${version}&cursor=0`,
    context
  )
}
export async function getMusics(version: any, context: ContextType) {
  return await fetch.get(
    `${BaseUrl}/api/v1/rank/music_rising_rank?cycle=W1&version=${version}&cursor=0`,
    context
  )
}
export async function getTags(version: any, context: ContextType) {
  return await fetch.get(
    `${BaseUrl}/api/v1/rank/tag_rising_rank?cycle=W1&version=${version}&cursor=0`,
    context
  )
}
export async function getTagVersion(context: ContextType) {
  return await fetch.get(
    `${BaseUrl}/api/v1/rank/versions?rank_type=TAG_RISING&rank_cycle=W1`,
    context
  )
}

export async function getMusicVersion(context: ContextType) {
  return await fetch.get(
    `${BaseUrl}/api/v1/rank/versions?rank_type=MUSIC_RISING&rank_cycle=W1`,
    context
  )
}

//商品
export async function getGoodsVideoList(context: ContextType) {
  return await fetch.get(`${BaseUrl}/api/v1/homepage/hot-promote-videos`, context)
}

export async function getGoodsList(version: any, context: ContextType) {
  return await fetch.get(`${BaseUrl}/api/v1/homepage/goods-rank`, context)
}

export async function getGoodsVersion(context: ContextType) {
  return await fetch.get(
    `${BaseUrl}/api/v1/rank/versions?rank_type=PRODUCT_RISING&rank_cycle=H24`,
    context
  )
}

export async function getProductVersion(context: ContextType) {
  return await fetch.get(
    `${BaseUrl}/api/v1/rank/versions?rank_type=VIDEO_COMMERCE_RISING&rank_cycle=H24`,
    context
  )
}

export async function getProductList(version: any, context: ContextType) {
  return await fetch.get(
    `${BaseUrl}/api/v1/rank/video_commerce_rising_rank?cycle=H24&version=${version}&cursor=0`,
    context
  )
}

export async function getGoodsTrend(context: ContextType) {
  const dateObj = getDayDate(7)
  // console.log('什么接口请求：：', `${BaseUrl}/api/v1/homepage/product/trend`)
  return await fetch.get(`${BaseUrl}/api/v1/homepage/product/trend`, context)
}
export async function getGoodsTrendChart(context: ContextType) {
  const dateObj = getDayDate(7)
  return await fetch.get(
    `${BaseUrl}/api/v1/product/region/sales/trend?country_code=GB&start_day=${dateObj.start}&end_day=${dateObj.end}`,
    context
  )
}

export async function getRegions(context: ContextType) {
  return await fetch.get(`${BaseUrl}/api/v1/tikmeta/portal/region`, context)
}

// 获取用户套餐信息
export async function getCurrentCombo(systemId = 14, authorization = getToken()) {
  console.log('authorization!!', authorization)

  return await fetch.get2(
    `${BaseUrl1}/api/v1/user/accounts/current-combo?system_id=${systemId}`,
    {},
    {
      'system-id': '14',
      authorization,
      'Content-Type': 'application/json'
    }
  )
}

export async function getSubscriptionsData() {
  return await fetch.get2(
    `${BaseUrl1}/api/v1/user/subscriptions/list?page_num=1&page_size=1&system_ids=14&status_str=3&algorithm_id=${algorithm()}`,
    {},
    {
      'system-id': '14',
      authorization: getToken(),
      'Content-Type': 'application/json'
    }
  )
}

//优惠活动信息 未登录
export async function getPayDiscountInfoUnToken(params) {
  // const _params = new URLSearchParams({ ...params, algorithm_id: algorithm() })
  // _params.set('marketing_codes', params?.marketing_codes)
  const url = `${BaseUrl1}/api/v1/user/charge/marketing/list/anonymous/pay-discounts?&algorithm_id=${algorithm()}system_id=${
    params?.systemId ?? 14
  }`
  const targetUrl = getUrlParams(url, params)
  return await fetch.get2(targetUrl, {
    'system-id': '14',
    'Content-Type': 'application/json;charset=UTF-8'
  })
}

//优惠活动信息
export async function getPayDiscountInfo(
  systemId = 14,
  type = 5,
  device_id,
  authorization = getToken(),
  other = {} as any
) {
  const url = `${BaseUrl1}/api/v1/user/charge/marketing/list/pay-discounts?system_id=${systemId}&marketing_type=${type}&algorithm_id=${algorithm()}`

  const targetUrl = getUrlParams(url, other)

  return await fetch.get2(
    targetUrl,
    {},
    {
      'system-id': '14',
      'device-id': device_id,
      authorization,
      'Content-Type': 'application/json'
    }
  )
}

// 领取活动会员
export async function getActivityVip(systemId = 14, id, device_id) {
  return await fetch.get2(
    `${BaseUrl1}/api/v1/user/charge/marketing/invitee-award?system_id=${systemId}&marketing_id=${id}&algorithm_id=${algorithm()}`,
    {},
    {
      'system-id': '14',
      'device-id': device_id,
      authorization: getToken(),
      'Content-Type': 'application/json'
    }
  )
}

//公告
export async function getNotifications() {
  return await fetch.get2(
    `${BaseUrl1}/api/v1/portal/user-notifications/listsV2?page_size=20&page_num=1&system_id=14`,
    {},
    {
      'system-id': '14',
      authorization: getToken(),
      'Content-Type': 'application/json'
    }
  )
}

export async function readNotifications(data: any, context: ContextType) {
  return await fetch.post2(`${BaseUrl1}/api/v1/portal/user-notifications/read`, context, data)
}

// 获取用户调查问卷
export const getQuestionnaire = async () => {
  return await fetch.get2(
    `${BaseUrl}/api/v1/questionnaire`,
    {},
    {
      'system-id': '14',
      authorization: getToken(),
      'Content-Type': 'application/json'
    }
  )
}

// 提交用户调查问卷
export const submitQuestionnaire = async (data) => {
  return await fetch.post2(`${BaseUrl}/api/v1/questionnaire`, {}, data)
}
//
export async function getRecommendBlog({
  language,
  context,
  needContent,
  type,
  pageSize
}: {
  language: any
  needContent: any
  context: ContextType
  type: number
  pageSize: number
}) {
  // console.log(`${BaseUrl}/api/v1/blogs/list?language=${language}&needContent=${needContent}`)

  return await fetch.get(
    `${BaseUrl}/api/v1/blogs/list?language=${language}&need_content=${needContent}&type=${type}&page_size=${pageSize}`,
    context
  )
}

// 获取当前购买套餐信息
export async function getPrices(params, context) {
  return await fetch.get(
    `${BaseUrl1}/api/v1/user/combo/public-prices?system_ids=${params?.systemId ?? 14}`,
    context
  )
}
// google 登录
export const ssoLogin = () => {
  return fetch.get(`${BaseUrl1}/api/v1/user/third-login/url/google?system_id=14&invite_code=${InviteCode.getKey()}&redirect_uri=${BaseUrl}/auth`, {});
};