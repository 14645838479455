//操作dom节点
class Node {
    el: HTMLElement | null;
    selector: string;
    constructor(selector: string) {
        this.selector = selector;
        this.el = document.querySelector(selector);
    }
    remove = () => {
        const els = document.querySelectorAll(
            this.selector
        ) as NodeListOf<HTMLElement>;
        if (els && els.length && els[0]) {
            els[0].parentNode?.removeChild(els[0]);
        }
    };
}

export default Node;
