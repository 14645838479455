import Navbar from './navbar/index'
import Footer from './footer/index'
import FooterBtns from './footerBtns/index'
import Script from 'next/script'
import React, { useEffect, useState } from 'react'
import { watchDom } from '@/watch'
import { useTranslation } from 'next-i18next'
let watchDoms: any = null
type Props = {
  footer: boolean
  header: boolean
  footerBtns?: boolean
  isHome?: boolean
  [anyData: string]: any
  userComboInfo?: any
}
export default function Layout(
  props: Props = {
    footer: true,
    header: true,
    footerBtns: false,
    isHome: false
  }
) {
  const { footer, header, footerBtns, theme, data } = props
  const hash = props.location && props.location.pathname
  const { t, i18n } = useTranslation(['home', "nav", "blogs"])
  useEffect(() => {
    if (watchDoms) clearInterval(watchDoms)
    watchDoms = watchDom(
      { ...data, name: { 'Sales Volume': t('Sales Volume'), 'Total Volume': t('Total Volume') } } ||
        undefined
    )
  }, [hash, data])
  return (
    <>
      {/* iconfont 链接 */}
      <Script id="alicdn" src="//at.alicdn.com/t/font_3347340_qro4dyqbgpi.js"></Script>
      {header && <Navbar {...props}></Navbar>}
      {props.children}
      {footer && <Footer {...props}></Footer>}
      {footerBtns && <FooterBtns></FooterBtns>}
    </>
  )
}
