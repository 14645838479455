function googleOneTap(
  {
    client_id,
    auto_select = false,
    cancel_on_tap_outside = false,
    context = "signin",
    ...otherOptions
  },
  callback
) {
  if (!client_id) {
    throw new Error("client_id is required");
  }

  if (typeof window !== "undefined" && window.document) {
    return new Promise((resolve, reject) => {
      const contextValue = ["signin", "signup", "use"].includes(context)
        ? context
        : "signin";
      const googleScript = document.createElement("script");
      googleScript.src = "https://accounts.google.com/gsi/client";
      googleScript.async = false;
      // googleScript.defer = true;
      document.head.appendChild(googleScript);
      googleScript.onload = function () {
        window.google.accounts.id.initialize({
          client_id: client_id,
          callback: callback,
          auto_select: auto_select,
          cancel_on_tap_outside: cancel_on_tap_outside,
          context: contextValue,
          ...otherOptions
        });
        resolve();
        // window.google.accounts.id.prompt();
      };
    });
  }
}

export default googleOneTap;
