import {getLangLink} from '@/utils/lang'
import ad from '@images/features/ad.png'
import inf from '@images/features/inf.png'
import product from '@images/features/product.png'
import shop from '@images/features/shop.png'
import sucai from '@images/features/sucai.png'
export const usePcMenu = () => {
    const pcUrl = process.env.portalUrl;
    return [
        {
            name: "Features",
            icon: "icon-shangpin-xian",
            // isNew: true,
            path: "/",
            // href: "/",
            children: [
                {
                    name: "PRODUCTS",
                    children: [
                        {
                            name: "Top Sales Products",
                            href: pcUrl + "/products/sales?name=hot",
                        },
                        {
                            name: "Product Search",
                            href: pcUrl + "/discovery/products",
                        },
                    ],
                },
                {
                    name: "INFLUENCERS",
                    children: [
                        {
                            name: "Top Sales Influencers",
                            href: pcUrl + "/influencer/sales?name=hot",
                        },
                        {
                            name: "Influencer Discovery",
                            href: pcUrl + "/discovery/influencers",
                        },
                    ],
                },
                {
                    name: "VIDEOS",
                    children: [
                        {
                            name: "Product Videos",
                            href: pcUrl + "/video/products?name=breakout",
                        },
                        {
                            name: "Promotion Videos",
                            href: pcUrl + "/video/promotion?name=breakout",
                        },
                        {
                            name: "Video Search",
                            href: pcUrl + "/discovery/videos",
                        },
                    ],
                },
                // {
                //     name: "ADS",
                //     children: [
                //         {
                //             name: "Breakout Ads",
                //             href: pcUrl + "/ads/soaringList",
                //         },
                //         { name: "Ads Library", href: pcUrl + "/discovery/ads" },
                //     ],
                // },
                {
                    name: "CONTENT",
                    children: [
                        {
                            name: "Songs",
                            href: pcUrl + "/content/songs?name=breakout",
                        },
                        {
                            name: "Hashtags",
                            href: pcUrl + "/content/hashtags?name=breakout",
                        },
                    ],
                },
            ],
        },
        
    ];
};
export const usePcSeoMenu = () => {
    const pcUrl = process.env.portalUrl;
    return [
        {
            name: "Features",
            icon: "icon-shangpin-xian",
            path: "/",
            children: [
                {
                    name: "Find Products",
                    desc: "Find Products Desc",
                    icon: product,
                    href: "/tiktok-products-trends"
                },
                {
                    name: "Pick Influencers",
                    desc: "Pick Influencers Desc",
                    icon: inf,
                    href: "/tiktok-influencer-marketing"
                },
                {
                    name: "Discover Videos",
                    desc: "Discover Videos Desc",
                    icon: sucai,
                    href: "/tiktok-videos-analytics"
                },
                {
                    name: "Analyze Shops",
                    desc: "Analyze Shops Desc",
                    icon: shop,
                    href: "/tiktok-shops-analytics"
                },
                // {
                //     name: "TikTok Ads",
                //     desc: "TikTok Ads Desc",
                //     icon: ad,
                //     href: "/tiktok-ads-analytics"
                // },
            ],
        },
        
    ];
};
export const useNormalMenu = (locale) => {
    const mobileUrl = process.env.mobileUrl;
    const pcUrl = process.env.portalUrl;
    return [
        {
            name: "Pricing",
            icon: "icon-boke",
            path: "checkout",
            href: pcUrl + `/pricing`,
        },
        {
            name: "Resources",
            icon: "icon-boke",
            // href: `/blogs`,
            path: "blogs",
            children: [
                {
                    name: "Blogs",
                    icon: "icon-boke",
                    href: getLangLink('/blogs',locale),
                    path: getLangLink('/blogs',locale),
                },
                {
                    name: "Reports",
                    icon: "icon-boke",
                    href: getLangLink('/tiktok-reports',locale),
                    path: getLangLink('/tiktok-reports',locale)
                },
                {
                    name: "Posts",
                    href:  getLangLink('/posts',locale),
                    mhref:  getLangLink('/posts',locale),
                },
            ]
        }
    ]
}
export const useMenu = (locale) => {
    const mobileUrl = process.env.mobileUrl;
    const pcUrl = process.env.portalUrl;
    return [
        {
            name: "Pricing",
            icon: "icon-boke",
            mVisible: true, // 移动端暂时隐藏
            href: pcUrl + `/checkout`,
        },
        // {
        //     name: "Live",
        //     icon: "icon-shangpin-xian",
        //     isNew: true,
        //     show: false,
        //     mVisible: true, // 移动端暂时隐藏
        //     children: [
        //         {
        //             name: "Top Sales Live",
        //             href: pcUrl + "/live/rank",
        //         },
        //         {
        //             name: "Search Live",
        //             href: pcUrl + "/discovery/live?keyword=",
        //         },
        //     ],
        // },
        // {
        //     name: "Shop",
        //     icon: "icon-shangpin-xian",
        //     isNew: true,
        //     show: false,
        //     mVisible: true, // 移动端暂时隐藏
        //     children: [
        //         {
        //             name: "Top Sales Shops",
        //             href: pcUrl + "/products/sales?name=breakout",
        //         },
        //         {
        //             name: "Search Shops",
        //             href: pcUrl + "/discovery/products?keyword=",
        //         },
        //     ],
        // },
        {
            name: "Products",
            icon: "icon-shangpin-xian",
            isNew: false,
            show: false,
            href: pcUrl + `/tiktok-products-trends`,
            mhref: `/tiktok-products-trends`,
            // children: [
            //     {
            //         name: "Top Sales Products",
            //         href: pcUrl + "/products/sales",
            //         mhref: mobileUrl + "/rank?type=Goods&rankType=breakout",
            //     },
            //     {
            //         name: "Product Search",
            //         href: pcUrl + "/discovery/products?keyword=",
            //     },
            // ],
        },
        {
            name: "Influencers",
            icon: "icon-gerenzhongxin1",
            show: false,
            href: pcUrl + `/tiktok-influencer-marketing`,
            mhref: `/tiktok-influencer-marketing`,
            // children: [
            //     {
            //         name: "Top Sales Influencers",
            //         href: pcUrl + "/influencer/sales",
            //         mhref:
            //             mobileUrl + "/rank?type=Influencers&rankType=breakout",
            //     },
            //     {
            //         name: "Influencer Discovery",
            //         href: pcUrl + "/discovery/influencers?keyword=",
            //         mhref: mobileUrl + "/discovery?type=influencers",
            //     },
            // ],
        },
        {
            name: "Videos",
            icon: "icon-shipinzhibo",
            show: false,
            href: pcUrl + `/tiktok-videos-analytics`,
            mhref: `/tiktok-videos-analytics`,
            // children: [
            //     {
            //         name: "Product Videos",
            //         href: pcUrl + "/video/products",
            //         mhref: mobileUrl + "/rank?type=Videos&rankType=breakout",
            //     },
            //     {
            //         name: "Promotion Videos",
            //         href: pcUrl + "/video/promotion?name=breakout",
            //     },
            //     {
            //         name: "Video Search",
            //         href: pcUrl + "/discovery/videos?keyword=",
            //         mhref: mobileUrl + "/discovery?type=videos",
            //     },
            // ],
        },
        // {
        //     name: "ADS",
        //     icon: "icon-shangpin-xian",
        //     show: true,
        //     isNew: false,
        //     mVisible: true, // 移动端暂时隐藏
        //     children: [
        //         {
        //             name: "Breakout Ads",
        //             href: pcUrl + "/ads/soaringList",
        //         },
        //         {
        //             name: "Ads Library",
        //             href: pcUrl + "/discovery/ads?keyword=",
        //         },
        //     ],
        // },
        // {
        //     name: "findContent",
        //     icon: "icon-sucai2",
        //     show: false,
        //     children: [
        //         {
        //             name: "hotMusics",
        //             href: "/portal/content/songs?name=breakout",
        //             // mhref: "/global/mobile/content/songs?name=breakout",
        //         },
        //         {
        //             name: "searchSongs",
        //             href: "/portal/discovery/songs?keyword=",
        //             // mhref: "/global/mobile/discovery/songs?keyword=",
        //         },
        //         {
        //             name: "hotTags",
        //             href: "/portal/content/hashtags?name=breakout",
        //             // mhref: "/global/mobile/content/hashtags?name=breakout",
        //         },
        //         {
        //             name: "searchHashtags",
        //             href: "/portal/discovery/hashtags?keyword=",
        //             // mhref: "/global/mobile/discovery/hashtags?keyword=",
        //         },
        //     ],
        // },
        {
            name: "Resources",
            icon: "icon-boke",
            show: true,
            isNew: false,
            children: [
                {
                    name: "Blogs",
                    href: getLangLink('/blogs',locale),
                    mhref:  getLangLink('/blogs',locale),
                },
                {
                    name: "Reports",
                    href:  getLangLink('/tiktok-reports',locale),
                    mhref:  getLangLink('/tiktok-reports',locale),
                },
                {
                    name: "Posts",
                    href:  getLangLink('/posts',locale),
                    mhref:  getLangLink('/posts',locale),
                },
                {
                    name: "Pricing",
                    href:  getLangLink('/pricing',locale),
                    mhref:  getLangLink('/pricing',locale),
                },
                {
                    name: "Sitemap",
                    href:  getLangLink('/sitemap',locale),
                    mhref:  getLangLink('/sitemap',locale),
                },
            ],
        },
        // {
        //     name: "About us",
        //     show: true,
        //     href:  getLangLink('/about-us',locale),
        //     mhref: getLangLink('/about-us',locale)
        // }
        // {
        //     name: "blogs",
        //     icon: "icon-boke",
        //     href: `/blogs`,
        //     mhref: `/blogs`,
        // },
    ];
};
