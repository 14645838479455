export const getLangLink = (link, locale)=>{
  return `${!locale||locale ==='en'?'':('/'+locale)}${link}`
}
// export const getNavigatorLang = (lang) => {
//   if (lang?.startsWith("vi")) {
//     // 越南
//     return "vi";
//   } else if (lang?.startsWith("id")) {
//     // 印尼
//     return "id";
//   } else {
//     return "en";
//   }
// }
// export const getCookieLang = (cookie) => {
//   if (cookie && cookie?.match(/responsive-locale=.+?;/)) {
//     return cookie?.match(/responsive-locale=.+?;/)?.[0].replace(/responsive-locale=(.+)?;/, '$1')
//   } else {
//     return null
//   }
// }
// const setLang = (language,host) => {
//   const temp = host.split(".").reverse();
//   const root_domain = "." + temp[1] + "." + temp[0];
//   // if (process.env.NODE_ENV !== 'development') {
//   // setCookie("responsive-locale", language, {
//   //     domain: root_domain
//   // });
//   // } else {
//     console.log(setCookie);
//   setCookie("responsive-locale", language);
//   // }
// };
// export const initLang = (cookie, navigatorLang, location) => {
//   let lang = getCookieLang(cookie);
//   const language = getNavigatorLang(navigatorLang)

//   // 如果没有缓存或者缓存不是已有语言，则获取浏览器的语言
//   if (!lang || !['en', 'id', 'vi'].includes(lang)) {
//     setLang(language, location.host);
//     lang = language
//   }

//   const str = location.href.split('/')
//   // // 判断当前链接的语言
//   const curLang = str.some(el=>el==='vi')? 'vi': str.some(el=>el==='id')?'id':'en'
//   console.log(location.pathname,'12312312');
//   // 如果链接和缓存语言不一致，则跳转到缓存语言的链接
//   if(curLang !== lang){
//     let path = location.pathname.replace('/vi','').replace('/id','')
//     console.log(`/${lang==='en'?'':lang}${path}${location.search}`);
//       return `/${lang==='en'?'':lang}${path}${location.search}`
//   } else {
//     return ''
//   }
// }