import React, { useEffect, useState } from 'react'
import styles from './gift.module.scss'

export default function Toast({ msg }) {
  const [visible, setVisible] = useState(!!msg)
  useEffect(() => {
    setVisible(!!msg)
  }, [msg])

  return <>{visible && <div className={`${styles['toast']}`}>{msg}</div>}</>
}
