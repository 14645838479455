import { useEffect, useState } from "react";
export const useWidth = () => {
    const hasWindow = typeof window !== "undefined";
    function getWidth() {
        const width = hasWindow ? (window as any)?._Globe_Width || 375 : 375;
        return width;
    }
    const [width, setWidth] = useState(0); // default width, detect on server.
    const handleResize = () => setWidth(window.innerWidth);
    const [display, setDisplay] = useState(false);

    useEffect(() => {
        if (!display) setDisplay(true);
        window.addEventListener("resize", handleResize);
        // setTimeout(() => {
        // console.log("ppp");
        if (typeof window !== "undefined") {
            handleResize();
        }
        // }, 1500);
        return () => window.removeEventListener("resize", handleResize);
    }, [handleResize]);
    return { width, display };
};
