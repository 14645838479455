/*
 * @Descripttion:
 * @version:
 * @Author: ared
 * @Date: 2022-10-27 14:46:04
 * @LastEditors: M.re undefined
 * @LastEditTime: 2023-10-25 17:49:29
 */
import styles from "../index.module.scss";
import { useTranslation } from "next-i18next";
import { LegacyRef, useEffect, useRef, useState } from "react";
import { useRouter } from "next/router";
const MobileMenuList = (props: any) => {
    const { handleMobileClose } = props;
    // debugger;
    console.log(props.menu, 'props.menu ====> ')
    const router = useRouter();
    const { t } = useTranslation("nav");
    const [showMenuList, setShowMenuList] = useState([''])
    //     "Products",
    //     "Influencers",
    //     "Videos",
    //     "Resources"
    //     // "findContent", content默认不展开
    // ]);

    const showMenu = (name: string) => {
        if (showMenuList.includes(name)) {
            setShowMenuList(showMenuList.filter((el) => el != name));
        } else {
            setShowMenuList([...showMenuList, name]);
        }
    };
    const MenuListDom = props.menu.map((el: any) => {
        return (
            <li className={styles.menuItem} key={el.name}>
                <div>
                    <div
                        className={styles.menuHeader}
                        onClick={() => showMenu(el.name)}
                    >
                        <div className={styles.menuHeaderLeft}>
                            <i className={"iconfont " + el.icon}></i>

                            <a href={el.mhref} onClick={() => !(el.children && el.children.length) && handleMobileClose}>
                            {t(el.name)}
                            </a>
                        </div>
                        {el.children && el.children.length ? (
                            <div
                                className={`${styles.menuHeaderRight} ${
                                    showMenuList.includes(el.name) &&
                                    "-rotate-180"
                                } transition duration-200 ease-in-out`}
                            >
                                <i className="iconfont icon-a-10xiala1"></i>
                            </div>
                        ) : null}
                    </div>
                    <div
                        className={`${styles.menuChild} ${
                            !showMenuList.includes(el.name) &&
                            styles.menuChildHidden
                        } `}
                    >
                        {el.children && el.children.length
                            ? el.children.map((item: any) => {
                                  return (
                                      <div key={item.name}>
                                          {item.mhref ? (
                                              <div onClick={handleMobileClose}>
                                                  <a
                                                      href={item.mhref || ""}
                                                      className={`${styles.menuChildItem}`}
                                                  >
                                                      {t(item.name)}
                                                  </a>
                                              </div>
                                          ) : null}
                                      </div>
                                  );
                              })
                            : null}
                    </div>
                </div>
            </li>
        );
    });
    return <ul className={styles.mobileMenuList}>{MenuListDom}</ul>;
};

export default MobileMenuList;
