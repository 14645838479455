import styles from '../index.module.scss'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import { useRouter } from 'next/router'
import faceBook from '@images/home/facebookfill@2x.png'
import whatsapp from '@images/home/whatsapp@2x.png'
import Zalo from '@images/home/Zalo@2x.png'
import LINE from '@images/home/LINE.png'
import LINECode from '@images/home/LINE-CODE.png'
import zaloCode from '@images/home/zalo-code0222.png'
import WhatsAppCode from '@images/home/whatApps0222.png'
import siyuPic from '@images/home/siyu-pic.png'
import icon from '@images/ic_drop@2x.png'
import buriedSdk from '@/utils/zz-buried/index'
import { getStorageInfo } from '@/utils/auth'
import { useState } from 'react'
import { Button, Modal } from 'antd'
import { log } from 'console'
const getUrlLang = () => {
  let str = window.location.pathname
  if (str.startsWith('/vi')) {
    return 'vi'
  } else if (str.startsWith('/id')) {
    return 'id'
  } else if (str.startsWith('/th')){
    return 'th'
  } else {
    return 'en'
  }
}
const Index = (props: any) => {
  const { t } = useTranslation('nav')
  const router = useRouter()
  const [showZalo, setZalo] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  
  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleOk = () => {
    setIsModalOpen(false)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  console.log('process.env.publicApiDomain', process.env.publicApiDomain, getUrlLang())

  const handleClick = (type: String) => {
    buriedSdk &&
      buriedSdk?.buried({
        event: '按钮点击',
        scene: '私域引导' + '-' + type,
        page_name: '官网-私域弹窗',
        page_url: document?.URL,
        // referer: '',
        user_id: getStorageInfo()?.userId,
        URL: process.env.publicApiDomain
      })
    // if (type === "Facebook") {
    //   window.open("https://m.me/101829126144736", "_blank");
    // }
    // if (type === "WhatsApp") {
    //   window.open("https://wa.me/message/P746BGJ3MAQGP1", "_blank");
    // }
    // if (type === "Zalo") {
    //   // setZalo(true);
    //   showModal();
    // }
  }

  const modalRender = () => {
    return (
      <div
        className={styles.zalo}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <div className={styles.top}>
          <i
            className="iconfont icon-a-55guanbi-da absolute top-[10px] right-[10px] cursor-pointer"
            onClick={handleCancel}
          ></i>
          <div
            className="absolute top-[32px] flex justify-center w-full
        "
          >
            <Image src={zaloCode} width={160} height={160}></Image>
          </div>
        </div>
        <div className={styles.bottom}>
          <div className={styles.titleZalo}>Contact us on Zalo</div>
          <div className={styles.title2Zalo}>MởZalo bấm nút quét QR đểquét kết bạn</div>
        </div>
      </div>
    )
  }
  return (
    <li className={`h-[70px] flex items-center ${styles.menusItem} `}>
      {/* <Modal
        title=""
        visible={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        closable={false}
        modalRender={modalRender}
        centered
        wrapClassName="ContactModal"
        width={280}
      ></Modal> */}
      <a className={styles.nohover + ' relative'}>
        <span>{t('TikTok Group')}</span>
        <i className={`iconfont icon-a-10xiala1 ml-[4px] ${styles.iconArrow} `}></i>
        <div className={styles.hoverBox + ''}>
          <div className={styles.group_wrap}>
            <div className="font-bold text-[16px] leading-[19px]">{t('TikTok Group Title')}</div>
            <div className="flex items-center">
              <ul>
                <li className="mt-[17px] whitespace-nowrap text-[16px]">
                  <span className="inline-block rounded-[50%] w-[22px] h-[22px] text-[#ffffff] text-center !leading-[22px] bg-[#000000] mr-[14px]">
                    1
                  </span>
                  {t('TikTok Group 1-1')}
                  <span className="text-[#FF3E5F] !font-bold">{t('TikTok Group 1-2')}</span>
                  {t('TikTok Group 1-3')}
                </li>
                <li className="mt-[12px] whitespace-nowrap text-[16px]">
                  <span className="inline-block rounded-[50%] w-[22px] h-[22px] text-[#ffffff] text-center !leading-[22px] bg-[#000000] mr-[14px]">
                    2
                  </span>
                  {t('TikTok Group 2-1')}
                  <span className="text-[#FF3E5F] !font-bold">40%</span>
                  {t('TikTok Group 2-2')}
                </li>
                <li className="mt-[12px] whitespace-nowrap text-[16px]">
                  <span className="inline-block rounded-[50%] w-[22px] h-[22px] text-[#ffffff] text-center !leading-[22px] bg-[#000000] mr-[14px]">
                    3
                  </span>
                  {t('TikTok Group 3-1')}
                  <span className="text-[#FF3E5F] !font-bold">{t('TikTok Group 3-2')}</span>
                </li>
              </ul>
              <div className="w-[136px] h-[124px] ml-[60px]">
                <Image src={siyuPic} alt=""></Image>
              </div>
            </div>
          </div>
          <div className="flex justify-between mt-[16px]">
            {['en', 'vi'].includes(getUrlLang()) ? <div className="w-[180px] h-[228px] bg-[#F7F8FB] rounded-[8px] flex flex-col items-center pt-[26px]">
              <div className="mb-[17px] flex items-center">
              <Image src={Zalo} width={20} height={20} alt="logo" />

                <span className="ml-[8px]">Zalo</span>
              </div>

              <Image
                src={zaloCode}
                width={110}
                height={110}
                alt="LINE-code"
              ></Image>
             
            </div>:  <div className="w-[180px] h-[228px] bg-[#F7F8FB] rounded-[8px] flex flex-col items-center pt-[26px]">
              <div className="mb-[17px] flex items-center">
                <Image src={LINE} width={20} height={20} alt="logo" />
                <span className="ml-[8px]">LINE</span>
              </div>

              <Image
                src={LINECode}
                width={110}
                height={110}
                alt="LINE-code"
              ></Image>
              {/* <a
                className={styles.join}
                href="https://zalo.me/g/cakvuc777"
                target="_blank"
                rel="noreferrer"
                onClick={() => handleClick('Zalo')}
              >
                {t('Click to join')}
                <div className="rotate-[90deg] ml-[5px]">
                  <Image src={icon} width={10} height={10}></Image>
                </div>
              </a> */}
            </div>}
            <div className="w-[180px] h-[228px] bg-[#F7F8FB] rounded-[8px] flex flex-col items-center pt-[26px]">
              <div className="mb-[17px] flex items-center">
                <Image src={whatsapp} width={20} height={20}></Image>
                <span className="ml-[8px]">{t('WhatsApp Group')}</span>
              </div>
              <Image src={WhatsAppCode} width={110} height={110}></Image>
              <a
                className={styles.join}
                href="https://wa.me/message/P746BGJ3MAQGP1"
                target="_blank"
                rel="noreferrer"
                onClick={() => handleClick('WhatsApp')}
              >
                {t('Click to join')}
                <div className="rotate-[90deg] ml-[5px]">
                  <Image src={icon} width={10} height={10}></Image>
                </div>
              </a>
            </div>
            <div className="w-[180px] h-[228px] bg-[#F7F8FB] rounded-[8px] flex flex-col items-center pt-[26px]">
              <div className="mb-[17px] flex items-center">
                <Image src={faceBook} width={20} height={20}></Image>
                <span className="ml-[8px]">{t('Facebook')}</span>
              </div>
              <a
                className={styles.join + ' !w-[120px] !h-[91px] !pr-[8px] !font-[500] text-center'}
                href="https://m.me/101829126144736"
                target="_blank"
                rel="noreferrer"
                onClick={() => handleClick('Facebook')}
              >
                <span className="flex">{t('Click to chat with us')}</span>
                <div className="rotate-[90deg] ml-[10px] w-[14px]">
                  <Image src={icon} width={14} height={14}></Image>
                </div>
              </a>
            </div>
          </div>
        </div>
      </a>
    </li>
  )
}

export default Index
