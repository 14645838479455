/*
 * @Date: 2022-12-09 15:47:12
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-12-12 20:33:35
 * @FilePath: /shoplus-global-website-node/watch/chart.js
 */
export default (dom, info) => {
  console.log(info, 'ionf')
  const data = info.trendChart.data.productSalesTrend;
  // const data = [
  //   { time: '10:10', call: 4, waiting: 2, people: 2 },
  //   { time: '10:15', call: 2, waiting: 6, people: 3 },
  //   { time: '10:20', call: 13, waiting: 2, people: 5 },
  //   { time: '10:25', call: 9, waiting: 9, people: 1 },
  //   { time: '10:30', call: 5, waiting: 2, people: 3 },
  //   { time: '10:35', call: 8, waiting: 2, people: 1 },
  //   { time: '10:40', call: 13, waiting: 1, people: 2 }
  // ];
  console.log(data, 'ionf')
  
  const chart = new G2.Chart({
    container: dom,
    autoFit: true,
    height: 300,
    padding: [10,10,20,10]

  });
  chart.theme({
    // 修改内置主题的某些配置
    defaultColor: '#FF3E5F',
  })
  chart.data(data);
  chart.scale({
    people: {
      min: 0,
      max: 10,
    },
    waiting: {
      min: 0,
      max: 10
    }
  });
  chart.legend(false);
  chart.axis('saleVolume', {
    line: {
      style: {
        stroke: 'null',
        strokeOpacity: 0
      }
    },
    label: null,
    grid: {
      line: {
        type: 'line',
        style: {
          stroke: '#ccc',
          strokeOpacity: 0.5
        }
      },
    },
    subTickLine: {
      count: 5,
  
    }
  });
  chart.axis('salesAmountUsdNum', {
    line: {
      
      style: {
        stroke: 'null',
        strokeOpacity: 0
      }
    },
    label: null,
    grid: null,
    subTickLine: {
      count: 5,
  
    }
  });
  chart.tooltip({
    shared: true,
    showTitle: false,
    customItems: (items) => {
      items = items.map(item => {
        let name = item.name == 'saleVolume' ? 'saleVolumeStr': 'salesAmountUsd'
        item.value =  item.data[name] 
        item.name = item.name == 'saleVolume' ? info.name?.["Sales Volume"] :info.name?.["Total Volume"]
        return item
      })
      return items
    },
   
  });
  chart.interval()
    .position('date*saleVolume')
    .color('#FF3E5F')
    .style({
      radius: [50, 50, 0, 0],
      fill: '#FF3E5F',
      fillOpacity: 1,
    })
    .size(40);
  chart.line()
    .position('date*salesAmountUsdNum')
    .size(3)
    .shape('smooth')
    .color('#FF911C')
    .style({
      stroke: '#FF911C',
      shadowBlur: 2,
      shadowColor: "rgba(0,0,0,0.05)",
      shadowOffsetY: 18,
      lineWidth: 3,
    })
  chart.axis({
    label: {
      offset: 2,
      fill: '#ccc',
      lineHeight: 40
    }
  })
  chart.interaction('active-region');
  chart.removeInteraction('legend-filter'); // 自定义图例，移除默认的分类图例筛选交互
  chart.render();
  
  return chart
}
