export const isPlainObject = (obj: any) =>
    Object.prototype.toString.call(obj) === "[object Object]";

export const under2Camal = (obj: any) => {
    const str = JSON.stringify(obj || {}).replace(
        /"([^"]+)":/g,
        (_, catched) =>
            `"${catched.replace(/_+(.)/g, (__: any, letter: any) =>
                letter.toUpperCase()
            )}":`
    );
    return JSON.parse(str);
};

export const strUnder2Camal = (str: string) => {
    return str.replace(/\_(\w)/g, function (all, letter) {
        return letter.toUpperCase();
    });
};

export const countChange = (value: any) => {
    if (value === null || value === undefined) return value;
    return +value;
};

export const camel2Under = function camel2Under(
    target: any,
    condition = () => true
): any {
    if (typeof target === "string") {
        return target.replace(
            /([a-z])([A-Z])/g,
            (_, letter1, letter2) => `${letter1}_${letter2.toLowerCase()}`
        );
    }
    if (target instanceof Array) {
        return target.map((item) => camel2Under(item, condition));
    }
    if (typeof target === "object" && target !== null) {
        const resultObj = {};
        Object.keys(target).forEach((key) => {
            const bol = condition();
            const newkey = camel2Under(key, condition);
            let value = target[key];
            if (typeof value === "object" && !(value instanceof Array)) {
                value = camel2Under(target[key], condition);
            } else if (
                value instanceof Array &&
                value.length > 0 &&
                typeof value[0] !== "string"
            ) {
                value = camel2Under(target[key], condition);
            }

            Object.assign(resultObj, {
                [bol ? newkey : key]: value,
            });
        });
        return resultObj;
    }
    return target;
};
