import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import tz from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
// import en from "dayjs/locale/en";
// import zh from "dayjs/locale/zh";
export const formatNumber = (val: number | undefined, addPrefix = false) => {
    if (val === undefined || Object.is(val, NaN)) return "-";

    if (addPrefix && +val === 0) return "-";

    const prefix = addPrefix && val > 0 ? "+" : "";

    if (Math.abs(val) >= 10e11) return prefix + (val / 10e11).toFixed(1) + "T";
    if (Math.abs(val) >= 10e8) return prefix + (val / 10e8).toFixed(1) + "B";
    if (Math.abs(val) >= 10e5) return prefix + (val / 10e5).toFixed(1) + "M";
    if (Math.abs(val) >= 10e2) return prefix + (val / 10e2).toFixed(1) + "K";

    return prefix + val.toString();
};

export const formatRate = (val: number | undefined, addPrefix = false) => {
    if (val === undefined || Object.is(val, NaN)) return "-";

    if (addPrefix && +val === 0) return "-";

    const prefix = addPrefix ? (val >= 0 ? "+" : "") : "";
    return prefix + (val * 100).toFixed(2) + "%";
};

export const formatTime = (time: Date) => {
    return dayjs(time).format("YYYY-MM-DD");
};

//0时区
export const formatTime2london = (time = new Date().getTime()) => {
    dayjs.extend(tz);
    dayjs.extend(utc);
    return dayjs(time).tz("Europe/london").format("YYYY-MM-DD");
};

//正数转为1,负数转为-1 其他0
export function platNum(n: number) {
    return n === 0 ? 0 : n / Math.abs(n);
}

export function formatDuration(val: number) {
    let sec = val;
    let min = 0;
    let hour = 0;
    if (val > 60) {
        min = parseInt(String(sec / 60));
        sec = parseInt(String(sec % 60));
        if (min > 60) {
            hour = parseInt(String(min / 60));
            min = parseInt(String(min % 60));
        }
    }

    return (
        (hour >= 10 ? hour : `0${hour}`) +
        ":" +
        (min >= 10 ? min : `0${min}`) +
        ":" +
        (sec >= 10 ? sec : `0${sec}`)
    );
}

//根据时间错算出当前时间
export const fromNow = (timeStamp = new Date().getTime()) => {
    // dayjs.locale({
    //   ...zh
    // });
    dayjs.extend(relativeTime);
    dayjs.extend(tz);
    dayjs.extend(utc);
    // console.log("pppp", locale.value === "zh");
    return dayjs(+timeStamp)
        .tz("Europe/london")
        .locale("en")
        .fromNow();
};

/**
 * 获取7 15 30 的startTime lastTime
 */
export const getDayDate = (time: number) => {
    const obj = {
        start: "",
        end: "",
    };
    const end = new Date();
    const start = new Date();
    end.setTime(end.getTime() - 3600 * 1000 * 24);
    start.setTime(start.getTime() - 3600 * 1000 * 24 * time);
    obj.start = dayjs(start).format("YYYY-MM-DD");
    obj.end = dayjs(end).format("YYYY-MM-DD");
    return obj;
};

//驼峰转中位线
const hyphenateRE = /\B([A-Z])/g;
export const hyphenate = function (str: string) {
    return str.replace(hyphenateRE, "-$1").toLowerCase();
};
