
export default (dom) => {
  
const data = [
  { item: '事例一', count: 40, percent: 0.4 },
  { item: '事例二', count: 21, percent: 0.3 },
  { item: '事例三', count: 17, percent: 0.17 },
  { item: '事例四', count: 13, percent: 0.13 },
  { item: '事例', count: 13, percent: 0.2 },
];

const chart = new G2.Chart({
  container: dom,
  autoFit: true,
  padding: [1,1,1,1],
  height: 150,
});
// 新建一个 view 用来单独渲染Annotation
chart.coordinate('theta', {
  radius: 0.75,
  innerRadius: 0.6,
});

chart.data(data);

chart.scale('percent', {
  formatter: val => {
    return '';
  },
});

chart.tooltip(false);

chart.legend(false);

chart
  .interval()
  .adjust('stack')
  .position('percent')
  .color('item', ['#FF3E5F', '#F4C537', '#4A84FB', '#71DCB1', '#A382E2'])
  .style({
    fillOpacity: 1,
  })
  .state({
    active: {
      style: element => {
        const shape = element.shape;
        return {
          lineWidth: 10,
          stroke: shape.attr('fill'),
          strokeOpacity: shape.attr('fillOpacity'),
        };
      },
    },
  });


chart.render();
  return chart
}
