// @flow
import * as React from "react";
import { useState, useEffect } from "react";
import { useTranslation } from "next-i18next";
import styles from "./index.module.scss";
import Logo from "@/public/images/logo-white.png";
import Image from "next/image";
import { useRouter } from "next/router";
import { useWidth } from "@/utils/resize";
import { subscribe } from "@/service/index";
import adLogo from "@images/adwinsLogo.png";
import ChromeLogo from "@images/chrome@2x.png";
import buriedSdk from "@/utils/zz-buried/index";
import Link from "next/link";
import {
  handleToPortal,
  handleToTag,
  handleToVideo,
  handleToMusic,
  handleToPotential,
} from "@/utils/sys";
import facebook from "@/public/images/footer/facebook@2x.png";
import instagram from "@/public/images/footer/instagram@2x.png";
import tw from "@/public/images/footer/tw@2x.png";
import youtube from "@/public/images/footer/youtube@2x.png";
import linkIn from "@/public/images/footer/link.png";
import { getLangLink } from "@/utils/lang";
// const Modal = dynamic(() => import('antd/lib/modal'))
import { Modal, BackTop } from "antd";
type Props = {};
const Index = (props: Props) => {
  const { t, i18n } = useTranslation(["footer"]);
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const router = useRouter();
  const [isAuth, setIsAuth] = useState(false);
  const { width, display } = useWidth();
  const [showCookieConsent, setShowCookieConsent] = useState('none');

  const isPc = width > 1024;
  const { locales, locale: activeLocale } = router;
  const contrylink = `${
    !activeLocale || activeLocale === "en" ? "" : "/" + activeLocale
  }`;
  const mobileUrl = process.env.mobileUrl;
  const pcUrl = process.env.portalUrl;
  const isHideTitle = React.useMemo(() => {
    //  不要展示页脚标题的页面
    const footerTitleNotShownPages = ["/insight", "/report/[slug]"];
    return footerTitleNotShownPages.includes(router.pathname);
  }, [router]);

  const errorMessage = (i18nString: string) => {
    Modal.error({
      content: t(i18nString),
    });
  };
  const successMessage = (i18nString: string) => {
    Modal.success({
      content: t(i18nString),
    });
  };

  const handleClick = (e: any) => {
    var reg = /^[0-9a-zA-Z_.-]+[@][0-9a-zA-Z_.-]+([.][a-zA-Z]+){1,2}$/;
    if (!email) return errorMessage(t("Enter email"));
    if (!message) return errorMessage(t("Enter message"));
    if (!reg.test(email)) return errorMessage(t("Error email"));

    subscribe({ contact: email, message: message }, {}).then((res) => {
      !res.code && successMessage(t("Sent successfully"));
      !res.code && setEmail("");
      !res.code && setMessage("");
    });
  };
  function getCookie(cname: string) {
    var name = cname + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i].trim();
      if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
    }
    return "";
  }
  const handleInput = (e: any) => {
    // console.log("ppp", e);
    setEmail(e.target.value);
  };
  const handleTextAreaInput = (e: any) => {
    setMessage(e.target.value);
  };
  const handleToPortal1 = () => {
    if (width > 900) {
      handleToPortal();
    } else {
      window.location.href = process.env.publicUrl + "/mobile/login";
    }
  };
  const footerBuried = (link) => {
    buriedSdk.buried({
      event: `Shoplus官网_${isPc ? "Web" : "H5"}`,
      scene: `底部菜单_${link}`,
      URL: process.env.publicApiDomain,
    });
  };
  // const jump = (str: string) => {
  //   window.location.href = `${locale === "en" ? "/" : "/zh/"}` + str;
  // };
  useEffect(() => {
    const isAuth = getCookie("authorized-token") ? true : false;
    setIsAuth(isAuth);
    if (!localStorage.getItem('cookieConsent')) {
      // 如果用户还没有同意Cookie，显示通知
      setShowCookieConsent('block');
    }
  }, []);
    // 用户接受Cookie
    const handleAcceptCookie = () => {
      // 设置Cookie同意状态为已同意
      localStorage.setItem('cookieConsent', 'true');
      // 隐藏通知
      setShowCookieConsent('none');
    };
  
    // 用户拒绝Cookie
    const handleRejectCookie = () => {
      // 在此可以添加拒绝Cookie后的操作
      // 例如，禁用某些功能或跟踪
      // 隐藏通知
      window.open('/privacy-policy','_blank')
    };
  return (
    <div className={`${styles.footer}`}>
            {/* <!-- Cookie同意通知 --> */}
            {showCookieConsent == 'block' && (
        <div className={styles['cookie-consent']}>
          <p>This website uses cookies to ensure you get the best experience on our website.</p>
          <div>
          <button className={styles['acc_button']} onClick={handleAcceptCookie}>Accept</button>
          <button className={`${styles['acc_button']} ${styles['link']}`} onClick={handleRejectCookie}>Cookie Setting</button>
          </div>
        </div>
      )}
      <div className={styles.footerBottom}>
        <div
          className={
            styles.footerBottomContent +
            " flex lg:block justify-between defeaultContainer"
          }
        >
          <div
            className={
              styles.info +
              " lg:float-left lg:!w-[40%] sm768:!w-[100%] sm768:mb-[90Px] md:!float-none md:!mb-[0Px] md:pb-[50Px] "
            }
          >
            <div className={styles.logo}>
              <Image lazyBoundary="10px" alt="" src={Logo}></Image>
            </div>
            <div
              className={`Poppins mt-[19Px] font-medium text-[#fff] ${
                isPc
                  ? "text-[20px]"
                  : "text-[16px] text-[rgba(255,255,255,0.5)]"
              }`}
            >
              {t("footer-title")}
            </div>
            <div
              className={`max-w-[240px] mt-[29Px] text-[rgba(255,255,255,0.5)] text-[14px] leading-[24px] md:max-w-full md:mt-[16Px]`}
            >
              {t("footer-tip")}
            </div>
            {width > 768 ? (
              <div
                className="Poppins mt-[90Px] text-[14px] whitespace-nowrap"
                style={{
                  color: "rgba(255, 255, 255, 0.5)",
                }}
              >
                © Shoplus. All Rights Reserved 2022
              </div>
            ) : null}
          </div>
          <div className={styles.hrefDiv}>
            <div className={styles.href}>
              <div className="Poppins head text-[20px] font-semibold text-[#fff]">
                {t("About")}
              </div>

              <div
                className="Poppins mt-[25Px] text-[15px]  cursor-pointer"
                style={{
                  color: "rgba(255, 255, 255, 0.8)",
                }}
              >
                <a
                  rel="nofollow"
                  onClick={() => {
                    footerBuried("about_about us");
                  }}
                  href={getLangLink("/about-us", activeLocale)}
                  style={{
                    color: "rgba(255, 255, 255, 0.8)",
                  }}
                >
                  {t("about-us")}
                </a>
              </div>

              <div
                className="Poppins mt-[25Px] text-[15px]  cursor-pointer "
                style={{
                  color: "rgba(255, 255, 255, 0.8)",
                }}
              >
                <a
                  rel="nofollow"
                  onClick={() => {
                    footerBuried("about_Privacy Policy");
                  }}
                  href={getLangLink("/privacy-policy", activeLocale)}
                  style={{
                    color: "rgba(255, 255, 255, 0.8)",
                  }}
                >
                  {t("Privacy Policy")}
                </a>
              </div>
              <div
                className="Poppins mt-[25Px] text-[15px] cursor-pointer "
                style={{
                  color: "rgba(255, 255, 255, 0.8)",
                }}
              >
                <a
                  rel="nofollow"
                  onClick={() => {
                    footerBuried("about_Terms of Service");
                  }}
                  href={getLangLink("/terms", activeLocale)}
                  style={{
                    color: "rgba(255, 255, 255, 0.8)",
                  }}
                >
                  {t("terms")}
                </a>
              </div>
              {isPc ? (
                <div
                  className="Poppins mt-[25Px] text-[15px] cursor-pointer "
                  style={{
                    color: "rgba(255, 255, 255, 0.8)",
                  }}
                >
                  <a
                    rel="nofollow"
                    onClick={() => {
                      footerBuried("about_Affiliate Program");
                    }}
                    href={getLangLink("/affiliate-program", activeLocale)}
                    style={{
                      color: "rgba(255, 255, 255, 0.8)",
                    }}
                  >
                    {t("Affiliate Program")}
                  </a>
                </div>
              ) : null}
            </div>
            <div className={styles.href}>
              <div className="whitespace-nowrap Poppins head text-[20px] font-semibold text-[#fff]">
                {t("Features")}
              </div>

              <div
                className="Poppins mt-[25Px] text-[15px] cursor-pointer  "
                style={{
                  color: "rgba(255, 255, 255, 0.8)",
                }}
              >
                <Link href="/tiktok-products-trends">
                  <a
                    rel="noreferrer"
                    onClick={() => {
                      footerBuried("Features_Find Products");
                    }}
                    target={"_blank"}
                    style={{
                      color: "rgba(255, 255, 255, 0.8)",
                    }}
                  >
                    {t("Find Products")}
                  </a>
                </Link>
              </div>
              <div
                className="Poppins mt-[25Px] text-[15px] cursor-pointer  "
                style={{
                  color: "rgba(255, 255, 255, 0.8)",
                }}
              >
                <Link href="/tiktok-influencer-marketing">
                  <a
                    rel="noreferrer"
                    onClick={() => {
                      footerBuried("Features_Pick Influencers");
                    }}
                    target={"_blank"}
                    style={{
                      color: "rgba(255, 255, 255, 0.8)",
                    }}
                  >
                    {t("Pick Influencers")}
                  </a>
                </Link>
              </div>
              <div
                className="Poppins mt-[25Px] text-[15px]  cursor-pointer "
                style={{
                  color: "rgba(255, 255, 255, 0.8)",
                }}
              >
                <Link href="/tiktok-videos-analytics">
                  <a
                    rel="noreferrer"
                    onClick={() => {
                      footerBuried("Features_Discover Videos");
                    }}
                    target={"_blank"}
                    style={{
                      color: "rgba(255, 255, 255, 0.8)",
                    }}
                  >
                    {t("Discover Videos")}
                  </a>
                </Link>
              </div>
              {isPc ? (
                <div
                  className="Poppins mt-[25Px] text-[15px]  cursor-pointer "
                  style={{
                    color: "rgba(255, 255, 255, 0.8)",
                  }}
                >
                  <a
                    rel="noreferrer"
                    onClick={() => {
                      footerBuried("Features_Analyze Shops");
                    }}
                    href={
                      isPc
                        ? pcUrl + `/tiktok-shops-analytics`
                        : mobileUrl + "/rank?type=Goods&rankType=breakout"
                    }
                    target={"_blank"}
                    style={{
                      color: "rgba(255, 255, 255, 0.8)",
                    }}
                  >
                    {t("Analyze Shops")}
                  </a>
                </div>
              ) : null}
              {/* {isPc ? (
                <div
                  className="Poppins mt-[25Px] text-[15px]  cursor-pointer "
                  style={{
                    color: "rgba(255, 255, 255, 0.8)",
                  }}
                >
                  <a
                    rel="noreferrer"
                    onClick={() => {
                      footerBuried("Features_TikTok Ads");
                    }}
                    href={
                      isPc
                        ? pcUrl + `/tiktok-ads-analytics`
                        : mobileUrl + "/rank?type=Goods&rankType=breakout"
                    }
                    target={"_blank"}
                    style={{
                      color: "rgba(255, 255, 255, 0.8)",
                    }}
                  >
                    {t("TikTok Ads")}
                  </a>
                </div>
              ) : null} */}
            </div>
            <div className={styles.href + " md:mt-[40px]"}>
              <div className="whitespace-nowrap Poppins head text-[20px] font-semibold text-[#fff]">
                {t("Resources")}
              </div>

              <div
                className="Poppins mt-[25Px] text-[15px] cursor-pointer  "
                style={{
                  color: "rgba(255, 255, 255, 0.8)",
                }}
              >
                <a
                  rel="nofollow"
                  onClick={() => {
                    footerBuried("Resources_Blogs");
                  }}
                  href={getLangLink("/blogs", activeLocale)}
                  style={{
                    color: "rgba(255, 255, 255, 0.8)",
                  }}
                >
                  {t("Blogs")}
                </a>
              </div>
              <div
                className="Poppins mt-[25Px] text-[15px] cursor-pointer  "
                style={{
                  color: "rgba(255, 255, 255, 0.8)",
                }}
              >
                <a
                  rel="nofollow"
                  onClick={() => {
                    footerBuried("Resources_Reports");
                  }}
                  href={getLangLink("/tiktok-reports", activeLocale)}
                  style={{
                    color: "rgba(255, 255, 255, 0.8)",
                  }}
                >
                  {t("Reports")}
                </a>
              </div>
              <div
                className="Poppins mt-[25Px] text-[15px] cursor-pointer  "
                style={{
                  color: "rgba(255, 255, 255, 0.8)",
                }}
              >
                <a
                  rel="nofollow"
                  onClick={() => {
                    footerBuried("Resources_Reports");
                  }}
                  href={getLangLink("/posts", activeLocale)}
                  style={{
                    color: "rgba(255, 255, 255, 0.8)",
                  }}
                >
                  {t("Posts")}
                </a>
              </div>
              <div
                className="Poppins mt-[25Px] text-[15px] cursor-pointer  "
                style={{
                  color: "rgba(255, 255, 255, 0.8)",
                }}
              >
                <a
                  rel="nofollow"
                  onClick={() => {
                    footerBuried("Resources_Reports");
                  }}
                  href={getLangLink("/pricing", activeLocale)}
                  style={{
                    color: "rgba(255, 255, 255, 0.8)",
                  }}
                >
                  {t("Pricing")}
                </a>
              </div>
              <div
                className="Poppins mt-[25Px] text-[15px] cursor-pointer  "
                style={{
                  color: "rgba(255, 255, 255, 0.8)",
                }}
              >
                <a
                  rel="nofollow"
                  onClick={() => {
                    footerBuried("Resources_Reports");
                  }}
                  href={getLangLink("/sitemap", activeLocale)}
                  style={{
                    color: "rgba(255, 255, 255, 0.8)",
                  }}
                >
                  {t("Sitemap")}
                </a>
              </div>
            </div>
            {!isPc ? (
              <div className={styles.href}>
                <div className="whitespace-nowrap Poppins head text-[20px] font-semibold text-[#fff]">
                  {t("Contact Us")}
                </div>
                <div className="Poppins mt-[22Px] text-[5px] font-medium text-[#fff]  flex">
                  <a
                    rel="noreferrer"
                    href="https://www.linkedin.com/company/shoplus2023/"
                    target={"_blank"}
                    style={{
                      background: "rgba(255, 255, 255, 0.1)",
                    }}
                    className="cursor-pointer mr-[9Px] w-[40Px] block h-[40Px]  p-[8Px]  rounded-[100%]  relative"
                  >
                    <Image
                      lazyBoundary="10px"
                      layout="intrinsic"
                      src={linkIn}
                      alt=""
                      width="40"
                      height="40"
                    ></Image>
                  </a>
                  <a
                    rel="noreferrer"
                    href="https://www.facebook.com/profile.php?id=100089456527236"
                    target={"_blank"}
                    style={{
                      background: "rgba(255, 255, 255, 0.1)",
                    }}
                    className="cursor-pointer mx-[9Px] w-[40Px] block h-[40Px] p-[8Px] rounded-[100%] relative"
                  >
                    <Image
                      lazyBoundary="10px"
                      layout="intrinsic"
                      src={facebook}
                      alt=""
                      width="40"
                      height="40"
                    ></Image>
                  </a>
                  <a
                    rel="noreferrer"
                    href="https://twitter.com/ShoplusOfficial"
                    target={"_blank"}
                    style={{
                      background: "rgba(255, 255, 255, 0.1)",
                    }}
                    className="cursor-pointer mx-[9Px] w-[40Px] block h-[40Px]  p-[8Px] rounded-[100%]  relative"
                  >
                    <Image
                      lazyBoundary="10px"
                      layout="intrinsic"
                      src={tw}
                      alt=""
                      width="40"
                      height="40"
                    ></Image>
                  </a>
                </div>
                <div className="mt-[20Px] text-[14px] leading-[21px] text-[rgba(255,255,255,0.8)]">
                  marketing@Shoplus.net
                </div>
              </div>
            ) : null}
          </div>
          {isPc ? (
            <div className="lg:w-[100%] lg:pt-[70Px] md:!hidden ">
              <div className="Poppins head text-[20px] font-semibold text-[#fff]">
                {t("Contact Us")}
              </div>

              <div className="Poppins mt-[46Px] text-[5px] font-medium text-[#fff]  flex">
                <a
                  rel="noreferrer"
                  href="https://www.linkedin.com/company/shoplus2023/"
                  target={"_blank"}
                  style={{
                    background: "rgba(255, 255, 255, 0.1)",
                  }}
                  className="cursor-pointer mr-[9Px] w-[40Px] block h-[40Px]  p-[8Px]  rounded-[100%]  relative"
                >
                  <Image
                    lazyBoundary="10px"
                    layout="intrinsic"
                    src={linkIn}
                    alt=""
                    width="40"
                    height="40"
                  ></Image>
                </a>
                <a
                  rel="noreferrer"
                  href="https://www.facebook.com/profile.php?id=100089456527236"
                  target={"_blank"}
                  style={{
                    background: "rgba(255, 255, 255, 0.1)",
                  }}
                  className="cursor-pointer mx-[9Px] w-[40Px] block h-[40Px] p-[8Px] rounded-[100%] relative"
                >
                  <Image
                    lazyBoundary="10px"
                    layout="intrinsic"
                    src={facebook}
                    alt=""
                    width="40"
                    height="40"
                  ></Image>
                </a>
                <a
                  rel="noreferrer"
                  href="https://twitter.com/ShoplusOfficial"
                  target={"_blank"}
                  style={{
                    background: "rgba(255, 255, 255, 0.1)",
                  }}
                  className="cursor-pointer mx-[9Px] w-[40Px] block h-[40Px]  p-[8Px] rounded-[100%]  relative"
                >
                  <Image
                    lazyBoundary="10px"
                    layout="intrinsic"
                    src={tw}
                    alt=""
                    width="40"
                    height="40"
                  ></Image>
                </a>
              </div>
              <div className="mt-[16Px] text-[16px] leading-[25px] text-[rgba(255,255,255,0.8)]">
                marketing@Shoplus.net
              </div>
              <input
                onInput={handleInput}
                placeholder={t("Your Email")}
                style={{
                  background: "rgba(255, 255, 255, 0.1)",
                }}
                className="pl-[20px] text-[12px] w-[335Px] h-[40Px] rounded-[4Px] mt-[14Px] py-[10Px] Px-[16Px] text-[#fff] lg:!w-[100%]"
              ></input>
              <textarea
                onInput={handleTextAreaInput}
                placeholder={t("Leave us a message")}
                className="pl-[20px]  text-[12px] mt-[10Px] w-[335Px] rounded-[4Px]  h-[60Px] py-[10Px] Px-[16Px] text-[#fff]  lg:!w-[100%]"
                style={{
                  background: "rgba(255, 255, 255, 0.1)",
                }}
              ></textarea>
              <button
                onClick={handleClick}
                className={`${styles["a-send"]} text-[#fff]  mt-[25Px]  w-[126Px] py-[10Px] h-[40px] bg-[#FF3E5F] text-[14px] rounded-[20Px] backdrop-blur-[4.5Px] flex items-center justify-center`}
              >
                <i className="iconfont icon-fasongx mr-[10px]"></i> {t("Send")}
              </button>

              {width < 768 ? (
                <div
                  className={
                    "Poppins pt-[40Px] text-[14px]  whitespace-nowrap " +
                    ` ${styles.last}`
                  }
                  style={{
                    color: "rgba(255, 255, 255, 0.5)",
                  }}
                >
                  © Shoplus. All Rights Reserved 2022
                </div>
              ) : null}
            </div>
          ) : (
            <div>
              <input
                onInput={handleInput}
                placeholder={t("Your Email")}
                style={{
                  background: "rgba(255, 255, 255, 0.1)",
                }}
                className="pl-[20px] text-[12px] w-[335Px] h-[40Px] rounded-[4Px] mt-[40Px] py-[10Px] Px-[16Px] text-[#fff] lg:!w-[100%]"
              ></input>
              <textarea
                onInput={handleTextAreaInput}
                placeholder={t("Leave us a message")}
                className="pl-[20px]  text-[12px] mt-[10Px] w-[335Px] rounded-[4Px]  h-[60Px] py-[10Px] Px-[16Px] text-[#fff]  lg:!w-[100%]"
                style={{
                  background: "rgba(255, 255, 255, 0.1)",
                }}
              ></textarea>
              <button
                onClick={handleClick}
                className={`${styles["a-send"]} text-[#fff]  mt-[25Px]  w-[126Px] py-[10Px] h-[40px] bg-[#FF3E5F] text-[14px] rounded-[8Px] backdrop-blur-[4.5Px] flex items-center justify-center w-full`}
              >
                <i className="iconfont icon-fasongx mr-[10px]"></i> {t("Send")}
              </button>
              <div
                className={
                  "Poppins pt-[40Px] text-[14px]  whitespace-nowrap text-center w-full" +
                  ` ${styles.last}`
                }
                style={{
                  color: "rgba(255, 255, 255, 0.5)",
                }}
              >
                © Shoplus. All Rights Reserved 2022
              </div>
            </div>
          )}
        </div>
      </div>
      

      {width < 1024 ? (
        <div className="minlg1024:hidden">
          <BackTop>
            <div className="bg-[#00000073] rounded-full w-[40px] h-[40px] flex flex-col justify-center items-center">
              <svg
                className="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="4940"
                width="20"
                height="20"
              >
                <path
                  d="M455.387429 333.238857l-242.834286 284.233143a36.571429 36.571429 0 0 0 27.830857 60.342857h147.126857v173.092572a124.635429 124.635429 0 1 0 249.307429 0v-173.129143h136.155428a36.571429 36.571429 0 0 0 28.233143-59.794286l-233.764571-283.794286a73.142857 73.142857 0 0 0-112.054857-0.950857zM249.782857 36.571429h512a91.428571 91.428571 0 0 1 0 182.857142h-512a91.428571 91.428571 0 1 1 0-182.857142z"
                  p-id="4941"
                  fill="#ffffff"
                ></path>
              </svg>
            </div>
          </BackTop>
        </div>
      ) : null}
    </div>
  );
};

const Footer = (props: any) => {
  const { width, display } = useWidth();
  const isPc = width > 1024;
  return (
    <div className={`${!isPc ? styles.mobileFooter : null}`}>
      <Index />
    </div>
  );
};

export default Footer;
